<template>
  <div class="ClientMain">
    <nav>
      <v-app-bar color="white" flat app>
        <v-img max-width="90px" src="../../public/faviconrzhd.png" pl="0"></v-img>
        <v-toolbar-title class="ml-3">
          <div class="Header">Дирекция железнодорожных вокзалов —</div>
          <div class="text-none mr-3 font-family-RussianRail">филиал ОАО «РЖД»</div>
        </v-toolbar-title>
      </v-app-bar>
    </nav>
    <div class="Reference">
      <h2 class="NameOfService">Управление станциями</h2>
      <div>
        Загруженный список:
        <b v-if="this.inputFile">{{ this.inputFile.length }} строк.</b>
        <b v-else>Отсутствует</b>
      </div>
      <div>
        Отсортированный список:
        <b v-if="this.outputFile">{{ this.outputFile.length }} строк.</b>
        <b v-else>Отсутствует</b>
      </div>
      <div>
        <input type="file" @change="uploadFile" ref="file" />
      </div>
      <div>
        <button @click="sortStations">Сортировка</button>
      </div>
      <div>
        <button @click="downloadFile">Скачать файл</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inputFile: null,
      outputFile: null
    };
  },
  methods: {
    uploadFile() {
      let reader = new FileReader();
      reader.onload = e => {
        const input = JSON.parse(e.target.result);
        const output = [];
        for (let i = 0; i < input.length; i++) {
          output.push({
            name: input[i].name_full.ru,
            code: input[i].code_exp
          });
        }
        this.inputFile = output;
      };
      reader.readAsText(this.$refs.file.files[0]);
    },
    downloadFile() {
      const blob = new Blob([JSON.stringify(this.outputFile)], { type: 'application/json' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'stations';
      link.click();
      URL.revokeObjectURL(link.href);
    },
    sortStations() {
      this.outputFile = this.inputFile.sort(this.sortFilter);
    },
    sortFilter(a, b) {
      if (a.code === '2000000') {
        return -1;
      }
      if (b.code === '2000000') {
        return 1;
      }
      if (a.code === '2004000') {
        return -1;
      }
      if (b.code === '2004000') {
        return 1;
      }
      if (a.code.startsWith('2')) {
        if (!b.code.startsWith('2')) {
          return -1;
        }
      }
      if (b.code.startsWith('2')) {
        if (!a.code.startsWith('2')) {
          return 1;
        }
      }
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    }
  }
};
</script>

<style scoped>
.ClientMain {
  background-color: rgba(57, 74, 88, 0.2);
  font-family: RussianRail;
  height: 100%;
}
.Reference {
  border-radius: 10px;
  margin: 10%;
  background-color: white;
  padding-bottom: 30px;
}
.NameOfService {
  padding-top: 30px;
  text-align: center;
}
.text-black input {
  color: black !important;
}
</style>
